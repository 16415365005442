export const realtorData = {
  email: 'ninarosales@unne.cl',
  phone:'+56961402141'
  // email: 'contacto@accionpanal.com',
};

export const footerData = {
  email: 'ninarosales@unne.cl',
  phone:'961402141',
  wsp:'961402141',
}
